<template>
  <div class="edrea_tm_section" id="about">
    <div class="section_inner">
      <div class="edrea_tm_about">
        <div class="left">
          <div class="image" style="height: 400px!important;">
            <!-- <img src="img/thumbs/1-1.jpg" alt="logo_assurcare" style="margin-top: 100px;"/> -->
            <div class="main" data-img-url="img/about/2.png" style="margin-top: 30%;"></div>
          </div>
        </div>
        <div class="right">
          <div class="text" style="color: #000;">
            <p>
                <strong><h3>Qui sommes-nous?</h3></strong>
              </p>
            <p>
              <strong>Assur’Care</strong> est un produit d’assurance digitale de Assuraf. 
              Assuraf est un Assureur Conseil établi au Sénégal depuis 2020 et dans la sous-région 
              avec une offre complète de solutions d’assurances dotée d’une forte composante technologique 
              et une équipe expérimentée de professionnels de la tech et de dizaines d’années dans les plus grands courtiers du continent.
            </p>
            <p><strong><h3>Nos services</h3></strong></p>
            <p>
              Assur’Care révolutionne l’assurance santé avec un Tiers Payant digitalisé, 
              un réseau de soins étendu et une gestion simplifiée pour les professionnels de santé et les partenaires divers. <br>
              <strong>Moins de paperasse, plus de visibilité : tout se fait en ligne pour simplifier et faciliter la vie de tous ses utilisateurs.</strong>
            </p>
            <p>
              <strong><h3>Contactez-nous</h3></strong>
            </p>
            <p>
              +221 33 864 00 64<br><a style="color:#069299!important;text-decoration: none;" href="mailto:contact@assuraf.com">contact@assuraf.com</a> - <a style="color:#069299!important;text-decoration: none;" href="https://www.assuraf.com">www.assuraf.com</a>
            </p>
            <p>
              <a href="#cgu" @click="navFuntions.activeSection('infos')" style="color:#069299!important;text-decoration: none;">infos légales/CGU</a>
             
                  <!-- <a href="/infos/legale" style="color:#069299!important;text-decoration: none;">infos légales/CGU</a> -->
              
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { navFuntions } from "@/navFuntions";

export default {
  name: `AboutComponent`,
  data() {
    return {
      open: false,
      navFuntions,
    };
  }
};
</script>

<style scoped>
.edrea_tm_modalbox {
  top: -90px;
}
</style>
